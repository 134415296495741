import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const fadeInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const EducationWrapper = styled.div`
  position: relative;
  width: 80%;
  margin: 0 auto;
  padding: 3rem 0;
`;

export const SectionHeading = styled.h2`
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #f59e0b;
  margin-bottom: 2rem;
  position: relative;
  &::after {
    content: '';
    width: 100px;
    height: 4px;
    background-color: #f59e0b;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Divider = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: '';
    width: 3px;
    height: 70%;
    background-color: #d1d5db;
  }
`;

export const EducationContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: ${({ alignLeft }) => (alignLeft ? 'flex-start' : 'flex-end')};
  align-items: center;
  margin-bottom: 4rem;
  opacity: 0;
  transform: translateX(${({ alignLeft }) => (alignLeft ? '-50%' : '50%')});
  animation: ${({ inView, alignLeft }) => inView && (alignLeft ? fadeInLeft : fadeInRight)} 1s forwards;

  @media (max-width: 768px) {
    justify-content: center;
    transform: translateX(0);
    flex-direction: column;
  }
`;

export const EducationCard = styled.div`
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  width: 45%;
  text-align: left;

  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 1rem;
    text-align: center;
  }
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Badge = styled.span`
  color: #f59e0b;
  font-size: 1.25rem;
  font-weight: bold;
`;

export const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #f59e0b;
`;

export const Company = styled.p`
  color: #6b7280;
`;

export const Date = styled.p`
  color: #9ca3af;
  margin-bottom: 1rem;
`;

export const Technologies = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

export const IframeWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
`;
