import styled from 'styled-components';

export const ContactWrapper = styled.div`
  min-height: 100vh;
  background-color: #2d2d2d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
`;

export const FormWrapper = styled.div`
  position: relative;
  padding: 3rem 1rem;
  max-width: 30rem;
  margin: auto;
`;

export const GradientBackground = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(to right, #4c51bf, #6b46c1);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  transform: skewY(-6deg);
  border-radius: 1.5rem;
`;

export const FormContainer = styled.div`
  position: relative;
  background: #5a67d8;
  padding: 2.5rem 1rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 1.875rem;
  margin-bottom: 1.5rem;
  color: white;
`;

export const Description = styled.p`
  color: #a0aec0;
  margin-bottom: 1.5rem;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: box-shadow 0.2s;
  &:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: box-shadow 0.2s;
  height: 8rem;
  resize: vertical;
  &:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
`;

export const Button = styled.button`
  background-color: #4c51bf;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
  border: none;
  &:hover {
    background-color: #434190;
  }
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SuccessModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: #48bb78;
  padding: 2rem;
  border-radius: 0.375rem;
  text-align: center;
  position: relative;
  max-width: 90%;
  margin: auto;
  color: white;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
`;
