import React, { useState } from "react";
import axios from 'axios';
import {
  ContactWrapper,
  FormWrapper,
  GradientBackground,
  FormContainer,
  Title,
  Description,
  Input,
  Textarea,
  Button,
  FormActions,
  SuccessModal, // Import the new styled component
  ModalContent, // Import the new styled component
  CloseButton // Import the new styled component
} from "./ContactElements";
import ScrollAnimation from "react-animate-on-scroll";

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/contact', formData);
      console.log(response.data);
      setSuccess(true);
      setTimeout(() => setSuccess(false), 2000); // Hide success message after 5 seconds
    } catch (error) {
      console.error('Error sending message', error.response ? error.response.data : error.message);
      alert(`Failed to send message: ${error.response ? error.response.data : error.message}`);
    }
  };

  return (
    <ContactWrapper id="contact">
      <div className="Container">
        <div className="SectionTitle">Get In Touch</div>
        <ScrollAnimation animateIn="fadeIn">
          <FormWrapper>
            <GradientBackground />
            <FormContainer>
              <Title>Contact Us!</Title>
              <Description>Fill up the form below to send us a message.</Description>
              <form onSubmit={handleSubmit}>
                <Input type="text" placeholder="Name" name="name" value={formData.name} onChange={handleChange} />
                <Input type="email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} />
                <Input type="text" placeholder="Subject" name="subject" value={formData.subject} onChange={handleChange} />
                <Textarea placeholder="Type your message here..." name="message" value={formData.message} onChange={handleChange} />
                <FormActions>
                  <Button type="submit">Send ➤</Button>
                  <Button type="reset" onClick={() => setFormData({ name: '', email: '', subject: '', message: '' })}>Reset</Button>
                </FormActions>
              </form>
            </FormContainer>
          </FormWrapper>
        </ScrollAnimation>
      </div>
      {success && (
        <SuccessModal>
          <ModalContent>
            <CloseButton onClick={() => setSuccess(false)}>×</CloseButton>
            <p>Thank you for contacting us! We got your message.</p>
          </ModalContent>
        </SuccessModal>
      )}
    </ContactWrapper>
  );
}

export default Contact;
