// --------------------------------------- Project List
export const ProjectList = [
  
 
  
  {
    img: "/zenex.png",
    title: "Full Website Development for Zenex GIS Company",
    description:
      "Developed a comprehensive website for Zenex, a leading GIS  company. The project encompassed front-end  integrating advanced GIS mapping tools and APIs. The website showcases Zenex’s capabilities, offering a user-friendly interface .  ",
    tech_stack: ["Next.js", "React", "Tailwind CSS","Framer Motion","Three.js","Axios","Swiper and React Slick","React Hook Form","React Router","Sharp" ],
    github_url: "https://github.com/DIX2580/zen-eoo.git",
    demo_url: "http://zenexinfo.com/",
  },
  {
    img: "/podcast.jpg",
    title: "Frontend for Kunal-podcast",
    description:
      "Developed a comprehensive website for Mishka Productions,A dynamic and engaging platform where Kunnal posts his latest podcasts. Built using Next.js and enriched with smooth animations, this site offers a seamless and immersive user experience. ",
    tech_stack: ["Next Js", "Tailwind CSS","Framer Motion","Heroicons","React Owl Carousel","React Slick","Swiper","React Type Animation", ],
    github_url: "https://github.com/DIX2580/kunal-podcast-episode",
    demo_url: "https://kunal-podcast-v2.netlify.app/",
  },
  
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "/icon images/html.svg",
    name: "HTML",
  },
  {
    img: "/icon images/css.svg",
    name: "CSS",
  },
  {
    img: "/icon images/js.svg",
    name: "JavaScript",
  },
  {
    img: "/icon images/react.svg",
    name: "ReactJS",
  },

  {
    img: "/icon images/mongo.svg",
    name: "MongoDB",
  },
  
 
  {
    img: "/icon images/c.svg",
    name: "C",
  },
  {
    img: "/icon images/c++.svg",
    name: "C++",
  },

  {
    img: "icon images/mysql.svg",
    name: "MySQL",
  },
 
  
  
  {
    img: "/icon images/tailwind-css.svg",
    name: "TailwindCSS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
    name: "VS Code",
  },
];
