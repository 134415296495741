import React from 'react';
import { useInView } from 'react-intersection-observer';
import {
  EducationWrapper,
  SectionHeading,
  Divider,
  EducationContainer,
  EducationCard,
  BadgeWrapper,
  Title,
  Company,
  Date,
  IframeWrapper,
} from './EducationStyles';

const Education = () => {
  const { ref: refLeft, inView: inViewLeft } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: refRight, inView: inViewRight } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <EducationWrapper>
      <SectionHeading>Education</SectionHeading>
      <Divider />

      {/* New Section for Master of Computer Application */}
      <EducationContainer alignLeft ref={refLeft} inView={inViewLeft}>
        <BadgeWrapper>
          <iframe
            src="https://lottie.host/embed/cdf66de4-c705-4c2f-b5e3-edd9d03a6488/8AAlW6Gjvq.json"
            title="Lottie Animation"
            frameBorder="0"
          ></iframe>
        </BadgeWrapper>
        <EducationCard>
          <IframeWrapper>
            <iframe
              src="https://lottie.host/embed/cdf66de4-c705-4c2f-b5e3-edd9d03a6488/8AAlW6Gjvq.json"
              title="Lottie Animation"
              frameBorder="0"
            ></iframe>
          </IframeWrapper>
          <Title>Master of Computer Application</Title>
          <Company>KIIT University</Company>
          <Date>Currently pursuing</Date>
        </EducationCard>
      </EducationContainer>

      {/* Section for Bachelor of Science */}
      <EducationContainer ref={refRight} inView={inViewRight}>
        <BadgeWrapper>
          <iframe
            src="https://lottie.host/embed/cdf66de4-c705-4c2f-b5e3-edd9d03a6488/8AAlW6Gjvq.json"
            title="Lottie Animation"
            frameBorder="0"
          ></iframe>
        </BadgeWrapper>
        <EducationCard>
          <IframeWrapper>
            <iframe
              src="https://lottie.host/embed/cdf66de4-c705-4c2f-b5e3-edd9d03a6488/8AAlW6Gjvq.json"
              title="Lottie Animation"
              frameBorder="0"
            ></iframe>
          </IframeWrapper>
          <Title>Bachelor of Science</Title>
          <Company>Fakir Mohan University, Balasore</Company>
          <Date>2018 - 2021</Date>
        </EducationCard>
      </EducationContainer>

      {/* Section for Higher Secondary Education */}
      <EducationContainer alignLeft ref={refRight} inView={inViewRight}>
        <BadgeWrapper>
          <iframe
            src="https://lottie.host/embed/cdf66de4-c705-4c2f-b5e3-edd9d03a6488/8AAlW6Gjvq.json"
            title="Lottie Animation"
            frameBorder="0"
          ></iframe>
        </BadgeWrapper>
        <EducationCard>
          <IframeWrapper>
            <iframe
              src="https://lottie.host/embed/cdf66de4-c705-4c2f-b5e3-edd9d03a6488/8AAlW6Gjvq.json"
              title="Lottie Animation"
              frameBorder="0"
            ></iframe>
          </IframeWrapper>
          <Title>Council of Higher Secondary Education, Odisha</Title>
          <Company>Upendranath College, Soro</Company>
          <Date>2016 - 2018</Date>
        </EducationCard>
      </EducationContainer>
    </EducationWrapper>
  );
};

export default Education;
